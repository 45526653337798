
export default function AdminProductPreturi({ product }) {
    /**
     * Practic trebuie sa fie "PretValuta": "2,9" / 1.19 -din pretul cu TVA se scoate TVA-ul.
     * Daca nu este valuta,   "PretCuTVA": "14,2866" / 1.19
     * In sistem avem preturile cu TVA inclus, deoarce vindem si cu amanuntul – pentru personae fizice la casa de marcat.
     */
    return (
        <>
            <p className="text-success">Pret site (calculat): <strong>{product.pretSite}</strong> &euro;</p>
            <table className="table table-condensed" style={{ width: '40%' }}>
                <thead>
                    <th>Valoare</th>
                    <th>WinMentor</th>
                </thead>
                <tr>
                    <td>Pret vanzare</td>  <td>{product.pretVanzare}</td>
                </tr>
                <tr>
                    <td>Pret cu TVA</td> <td>{product.pretCuTVA}</td>
                </tr>
                <tr>
                    <td>Pret valuta</td> <td>{product.pretValuta}</td>
                </tr>
                <tr>
                    <td>Procent TVA</td> <td>{product.procentTVA}</td>
                </tr>

                <tr>
                    <td>UM</td> <td>{product.um}</td> <td></td>
                </tr>

            </table>
            
            <p>Promotie: {(product.promotie==="1")?'DA':'NU'}</p>
        </>
    )
}