import React, { useState, useEffect } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import { BsFilter } from 'react-icons/bs';
import { Container, Row, Col } from "react-bootstrap";
import FilterClase from "./FilterClase";
import FilterProducatori from "./FilterProducatori";

function Filter() {
    const [open, setOpen] = useState(false);
    //const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    // Effect to update isMobile state on window resize
    useEffect(() => {
      const handleResize = () => {
        const isNowMobile = window.innerWidth <= 768;
        //setIsMobile(isNowMobile);
        if (!isNowMobile) {
          setOpen(true); // Automatically open if not mobile
        }
      };
  
      window.addEventListener('resize', handleResize);
      handleResize(); // Initial check
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return (
        <>
            <Container id="filters">
                <Button
                    variant="outline-dark"
                    className="filter-toggle-button"
                    onClick={() => setOpen(!open)}
                    aria-controls="filter-tray"
                    aria-expanded={open}
                >
                    <BsFilter /> {/* Filter icon */}
                </Button>

                <Collapse in={open}>
                    <div id="filter-tray" className="filter-tray">

                        {/*<Row className="filter" ><Col>   <FilterName /> </Col></Row> *}
                        {/* <Row className="filter"><Col>  <FilterStock /> </Col></Row> */}
                        {/* <Row className="filter mt-2"><Col>  <FilterPrice /> </Col></Row> */}
                        <Row className="filter"><Col>  <FilterClase /> </Col></Row>
                        <Row className="filter"><Col>  <FilterProducatori /> </Col></Row>
                        {/* <Row className="filter"><Col>  <FilterSimbolClase /> </Col></Row> */}

                    </div>
                </Collapse>
            </Container>
        </>
    )
}

export default Filter;