import ReactDOM from 'react-dom/client';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import 'common/rolling.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import { Provider } from "react-redux";
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

import productsReducer from 'store/productsReducer';
import cartStore from 'store/cartStore';
import msgStore from 'store/msgStore';
import contentStore from 'store/contentStore';


const persistConfig = {
  key: 'cart',
  version: 1,
  blacklist: ['products', 'msg', 'content'],
  storage,
}


const rootReducer = combineReducers({
  products: productsReducer,
  cart: cartStore,
  msg: msgStore,
  content: contentStore
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const storePersisted = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false /*{
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }*/,
    }),
})

export const persistor = persistStore(storePersisted)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={storePersisted}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
