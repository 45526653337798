import React, { useEffect } from "react";
import { useSelector } from 'react-redux';
import { Container, Row, Col, Table } from "react-bootstrap";
import Filter from "components/facets/Filter";

import Loading from "components/common/Loading";
import ErrorMessage from "components/common/ErrorMessage";
import SearchResultsProductRow from "./SearchResults-ProductRow";
import SearchResultsProductRowBulk from "./SearchResults-ProductRowBulk";


function SearchResults({ user }) {
    const products = useSelector((store) => store.products.filteredProducts);
    const clase = useSelector((store) => store.products.clase);
    const shops = useSelector((store) => store.products.shops);
    const simbolClase = useSelector((store) => store.products.simbolClase);
    const producatori = useSelector((store) => store.products.producatori);
    const productsCount = useSelector((store) => store.products.count);
    const filteredCount = useSelector((store) => store.products.filteredCount);
    const isError = useSelector((store) => store.msg.error);
    const isLoading = useSelector((store) => store.msg.loading);
    const searchQuery = useSelector((store) => store.products.searchQuery);
    const activeMenu = useSelector((store) => store.products.activeMenu);


    useEffect(() => {
    }, []);

    useEffect(() => {
        //console.log("SearchResults useEffect (products)", products, products.length);
    },
        [products, isError, isLoading]);

    const getClasa = (id) => {
        if (!!id) {
            if (!!clase[id]) {
                return clase[id].display;
            } else {
                console.log("Undefined clasa for ", id);
                return "-";
            }
        } else {
            console.log("Undefined id clasa!");
        }
    }

    const getSimbolClasa = (id) => {
        if (!!id) {
            if (!!simbolClase[id]) {
                return simbolClase[id].display;
            } else {
                console.log("Undefined simbolClase for ", id);
                return "-";
            }
        } else {
            console.log("Undefined id simbolClase!");
        }
    }

    const getProducatori = (id) => {
        if (!!id) {
            if (!!producatori[id]) {
                return producatori[id].display;
            } else {
                console.log("Undefined producatori for ", id);
                return "-";
            }
        } else {
            console.log("Undefined id producatori!");
        }
    }

    const getRowClass = (idx) => {
        return (idx % 2 === 0) ? 'row-even' : 'row-odd';
    }

    let dataAvailable = !isLoading && !isError && !!(products) && !!(products.map) && (products.length > 0);
    let somethingSearched = (!!searchQuery && searchQuery.length > 0) || (!!activeMenu && activeMenu.id > 0);
    console.log("SearchResults dataAvailable", dataAvailable, "isError", isError, "isLoading", isLoading, "products", !!products, "productsCount", productsCount, "filteredCount", filteredCount, "somethingSearched", somethingSearched);
    return (
        <>
            <Container fluid className="ml-3 w-100" style={{ minHeight: "90vh" }}>
                <Row className="w-100">
                    <Col xxl={3} xl={3} lg={3} md={12} sm={12} xs={12} className="mr-3 bg-light">
                        {!!products && (products.length > 0) && <Filter />}
                    </Col>
                    <Col xxl={9} xl={9} lg={9} md={12} sm={12} xs={12}  >
                        <Loading key="loadingMessageKey" />
                        <ErrorMessage key="errorMessageKey" />
                        {dataAvailable ? (
                            <React.Fragment key="resultsKey">
                                <>
                                    {activeMenu && activeMenu.id ? (<>
                                        <h3>{activeMenu.name}</h3>
                                        <p key="searchDesc" id="searchDesc">
                                            <small style={{ color: "#888" }}>{activeMenu.description}</small>
                                        </p>
                                        <hr />
                                    </>) : (
                                        <p key="searchDesc" id="searchDesc">
                                        </p>
                                    )}
                                    <small>Sunt afișate {filteredCount} din {productsCount} produse găsite.</small>

                                </>
                                <Table key="searchResults" id="searchResults" bordered hover size="sm" responsive="sm">
                                    <thead>
                                        <tr>
                                            <th className="align-middle text-center">PRODUCĂTOR</th>
                                            <th className="align-middle text-center">ARTICOL</th>
                                            <th className="align-middle text-center">ECH.</th>
                                            <th className="align-middle text-center">FIȘĂ<br />TECH.</th>
                                            <th className="align-middle text-center">STOC</th>
                                            <th className="align-middle text-center">UM</th>
                                            <th className="align-middle text-center">PREȚ &euro;<br /><small><small>FĂRĂ TVA</small></small></th>
                                            <th className="align-middle text-center" colSpan={2}>ADAUGĂ<br />ÎN COȘ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {products.map((p, idx) => (
                                            <React.Fragment key={`row-${p.codArticol}`} >
                                                {p.bulk.length > 0 ? (
                                                    <SearchResultsProductRowBulk key={`bulk-${p.codArticol}`} product={p} getClasa={getClasa} getSimbolClasa={getSimbolClasa} getProducatori={getProducatori} shops={shops} rowClass={getRowClass(idx)} />
                                                ) : (
                                                    <SearchResultsProductRow product={p} getClasa={getClasa} getSimbolClasa={getSimbolClasa} getProducatori={getProducatori} shops={shops} rowClass={getRowClass(idx)} />
                                                )}

                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </React.Fragment>
                        ) :
                            // Handle loading, error, and no products conditions
                            isLoading ? (
                                <></>
                            ) : isError ? (
                                <p>A apărut o eroare. Vă rugăm să încercați din nou.</p> // Show error message
                            ) : products && products.length === 0 && !somethingSearched ? (
                                <p>Vă rugăm, folosiți bara de căutare ...</p>
                            ) : products && products.length === 0 ? (
                                <p>Nu sunt rezultate, vă rugăm, folosiți termeni mai puțin restrictivi.</p> // Show no results message
                            ) : (
                                <p>Vă rugăm, folosiți bara de căutare ...</p> // first loading without any interaction
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SearchResults;
