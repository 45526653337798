import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setError, clearError, setTempConfirmation } from "store/msgStore";
import { ApiGet, ApiPut, ApiDel } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import AdminUserTypesAdd from "./AdminUserType-Add";
import { Form, Button, Tab, Tabs } from "react-bootstrap";
import { FiFolderMinus, FiFolderPlus, } from "react-icons/fi";
import { FaUserEdit } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { MdDeleteForever } from "react-icons/md";
import { RiForbid2Line } from "react-icons/ri";

export default function AdminUserTypesProd({ curentUser }) {
    const [data, setData] = useState([]); // need to do a deep copy

    let dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());

        ApiGet('/user-types-prod', (data) => { setData(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        refresh();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function beforeSaveCell(oldValue, newValue, row, column, done) {
        if (oldValue === newValue) {
            console.log("same value, ignore");
            done(false);
            return;
        }

        let payload = { ...row }
        payload[column['dataField']] = newValue
        console.log("Save type", payload);

        ApiPut('/user-types-prod', payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Valoarea [" + newValue + "] a fost salvata cu success."))
                refresh();
                done(true)
            },
            (error) => {
                dispatch(setError(error));
                done(false)
            });

        return { async: true };
    }


    const emptyValueValidator = (newValue, row, column) => {
        if (!(newValue)) {
            return {
                valid: false,
                message: 'Introduceti o valoare'
            };
        }
        return true;
    }

    const onChangeActive = (e) => {

        if (!e || !e.target) {
            return
        }
        let checked = e.target.checked;
        let rowId = e.target.value;
        console.log("Active changed", checked, rowId);

        let payload = data.find(r => r.id === rowId);
        payload.active = checked;

        console.log("Save type", payload);

        ApiPut('/user-types-prod', payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Tipul de utilizator a fost modificat cu success."))
                refresh();
            },
            (error) => {
                dispatch(setError(error));
            });
    }

    const activeFormatter = (cell, row) => {
        //console.log("cell active: ", cell, row);

        return (
            <Form.Check
                key={`active-${row.id}`}
                type="switch"
                id={`active-${row.id}`}
                checked={cell}
                value={row.id}
                disabled={row.usedBy > 0}
                onChange={onChangeActive}
            />

        )
    }

    const editFormatter = (cell, row) => {
        return (
            <Button as={NavLink} to={`./${row.id}`}
                variant="primary" size="sm" title="Drepturi si discounturi">
                <FaUserEdit />
            </Button>
        )
    }

    const deleteUserType = (userId) => {
        console.log("delete", userId);
        ApiDel('/user-type-prod/' + userId,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Tipul de utilizator a fost modificat cu success."))
                refresh();
            },
            (error) => {
                dispatch(setError(error));
            });
    }

    const deleteFormatter = (cell, row) => {
        if (row.active) {
            return (
                <span className="text-muted"><RiForbid2Line /></span>
            );
        }
        return (
            <Button
                onClick={() => deleteUserType(row.id)}
                variant="danger" size="sm" title="Șterge">
                <MdDeleteForever />
            </Button>
        )
    }

    const columns = [
        {
            dataField: 'active',
            text: 'Activ',
            sort: true,
            align: 'center',
            headerAlign: 'center',
            editable: false,
            formatter: activeFormatter,
            headerStyle: { width: '7%' }
        },
        {
            dataField: 'name',
            text: 'Nume',
            sort: true,
            editable: true,
            validator: emptyValueValidator
        },
        {
            dataField: 'description',
            text: 'Descriere',
            sort: true,
            editable: true,
            validator: emptyValueValidator
        },
        {
            dataField: 'usedBy',
            text: 'Nr.',
            sort: true,
            editable: false,
            headerStyle: { width: '5%' },
            headerAlign: 'center',
            align: 'center'
        },
        {
            dataField: 'edit',
            isDummyField: true,
            text: 'Modifica',
            align: 'center',
            headerAlign: 'center',
            sort: false,
            editable: false,
            formatter: editFormatter,
            headerStyle: { width: '7%' }
        },
        {
            dataField: 'delete',
            isDummyField: true,
            text: 'Sterge',
            align: 'center',
            headerAlign: 'center',
            sort: false,
            editable: false,
            formatter: deleteFormatter,
            headerStyle: { width: '7%' }
        },

    ];


    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];


    const expandRow = {
        renderer: row => (
            <>
                <h5>{row.name}</h5>
                <p className="text-muted">{row.description}</p>
                <dl>
                    <dt>Folosit de:</dt>
                    <dd><strong>{row.usedBy}</strong> utilizatori</dd>
                    <dt>Ultima modificare:</dt>
                    <dd>{row.updateTime}</dd>
                    <dd>{row.updateBy}</dd>
                    <dt>Creat initial:</dt>
                    <dd>{row.createTime}</dd>
                    <dd>{row.createdBy}</dd>
                </dl>
            </>
        ),
        showExpandColumn: true,
        expandByColumnOnly: true,
        expandHeaderColumnRenderer: ({ isAnyExpands }) => {
            return (<></>);
        },
        expandColumnRenderer: ({ expanded }) => {
            if (expanded) {
                return (<FiFolderMinus />);
            }
            return (<FiFolderPlus />);
        }
    };

    const rowClasses = (row, rowIndex) => {
        let classes = '';

        if (!row.active) {
            classes = 'bg-light text-danger';
        }

        return classes;
    };

    //https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
    return (
        <>
            <h1>Tipuri de utilizatori - acces producatori</h1>
            <p>Puteti defini oricate tipuri de utilizatori, fiecare va avea un set de drepturi la producatori.
                <br />Apoi aceste tipuri se pot aplica cu ușurință conturilor de partener.
            </p>

            <Tabs
                defaultActiveKey="userTypesList"
                id="userTypesTabs"
                className="mb-3"
            >
                <Tab eventKey="userTypesList" title="Lista">
                    <p>
                        <small>Tipurile inactive nu apar in selectii, se considera ca sunt inca "in lucru"</small>
                        <br />
                        <small>
                            <small><em>Restricții</em>: Nu puteți deactiva un tip care este folosit - trebuie modificat fiecare user care il foloseste. Nu puteți șterge un tip activ - trebuie mai intai sa fie dezactivat, si sa nu fie folosit de niciun user.</small>
                        </small>
                    </p>

                    {(<BootstrapTable
                        hover
                        keyField='id'
                        data={JSON.parse(JSON.stringify(data))}
                        columns={columns}
                        defaultSorted={defaultSorted}
                        cellEdit={cellEditFactory({
                            mode: 'dbclick',
                            beforeSaveCell
                        })}
                        rowClasses={rowClasses}
                        filterPosition="top"
                        condensed
                        expandRow={expandRow}
                    // striped
                    />
                    )}
                </Tab>
                <Tab eventKey="addUseType" title="+ Adauga">
                    <AdminUserTypesAdd refresh={refresh} />

                </Tab>
            </Tabs>
        </>
    );

}