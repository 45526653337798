import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { setError, setLoading, clearError, setTempConfirmation } from "store/msgStore";
import { ApiGet, ApiPost } from "services/ApiService";
import { Form, Button } from "react-bootstrap";
import { Buffer } from "buffer";
import { AiFillSave } from "react-icons/ai";
import AdminPageDelete from "./AdminPage-Delete";
import { IoMdArrowBack } from "react-icons/io";


export default function AdminPage({ currentUser }) {
    const params = useParams(); // parameters from URL (react-router)
    const blocks = useSelector((store) => store.content.blocks);
    const pages = useSelector((store) => store.content.pages);
    const miniCarousels = useSelector((store) => store.content.miniCarousels);

    const empty = {
        id: 0,
        status: 0,
        title: "",
        subtitle: "",
        block: "",
        menu: "",
        path: "",
        menuOrder: "",
        content: "",
        carousel: 0,
        carouselPages: []
    }
    const [data, setData] = useState(empty); // need to do a deep copy
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const [sumar, setSumar] = useState("");
    const [file, setFile] = useState(null);
    const [isAdd, setAdd] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loadData = (data) => {
        setTitle(data.title); // default;
        setData(data);
        if (!!data.content) {
            setText(Buffer.from(data.content, 'base64').toString());
        } else {
            setText("");
        }
        if (!!data.sumar) {
            setSumar(Buffer.from(data.sumar, 'base64').toString());
        } else {
            setSumar("");
        }
    }

    const refresh = () => {
        dispatch(clearError());
        if (params.pageId !== "+" && !!params.pageId) {
            setAdd(false);
            ApiGet('/content/page/' + params.pageId, (data) => { loadData(data) }, (error) => { dispatch(setError(error)); });
        } else {
            setAdd(true);
            setData(empty);
        }
    }

    useEffect(() => {
        //render
    }, [data, text, sumar, blocks, pages, miniCarousels]);

    useEffect(() => {
        refresh();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeTitle = (e) => {
        setData({ ...data, title: e.target.value })
    }

    const onChangeSubTitle = (e) => {
        setData({ ...data, subtitle: e.target.value })
    }

    const onChangeMenu = (e) => {
        setData({ ...data, menu: e.target.value })
    }

    const onChangeBlock = (e) => {
        setData({ ...data, block: e.target.value })
    }

    const onChangeMenuOrder = (e) => {
        setData({ ...data, menuOrder: e.target.value })
    }

    const onChangeUrl = (e) => {
        setData({ ...data, path: e.target.value })
    }

    const onChangeContent = (e) => {
        setText(e.target.value)
    }

    const onChangeSumar = (e) => {
        setSumar(e.target.value)
    }

    const onChangeFile = (e) => {
        console.log("File", e.target.files);
        setFile(e.target.files[0]);
    }

    const onChangeActive = (e) => {
        if (!e || !e.target) {
            return
        }
        let checked = e.target.checked;
        setData({ ...data, status: checked ? 1 : 0 })
    }

    const onChangeCarousel = (e) => {
        if (!e || !e.target) {
            return
        }
        let id = parseInt(e.target.value);
        let checked = e.target.checked;
        
        
        let c = data.carouselPages;
        if (checked) {
            c.push(id);
        } else {
            c = c.filter(i => i !== id);
        }
        console.log("onChangeCarousel", id, checked, c)
        setData({ ...data, carouselPages: c })
    }


    const goBack = (e) => {
        navigate(-1);
    }

    const savePage = (e) => {
        e.preventDefault();
        dispatch(setLoading(true));

        const formData = new FormData();

        let payload = {
            ...data,
            sumar: Buffer.from(sumar).toString("base64"),
            content: Buffer.from(text).toString("base64")
        };

        for (var key in payload) {
            formData.append(key, payload[key]);
        }
        formData.append('image', file);

        ApiPost('/content/page/' + data.id,
            formData,
            (data) => {
                dispatch(setLoading(false));
                dispatch(setTempConfirmation("Pagina a fost salvată"));
                goBack(e);
            },
            (error) => {
                dispatch(setLoading(false));
                dispatch(setError(error));
            });
    }
    
    return (
        <Form>
            {isAdd ? (<h1>Adaugă conținut</h1>) : (<h1>Modifica conținut: {title}</h1>)}

            <Form.Group className="mb-3" controlId="formActiv">
                <Form.Check
                    type="switch"
                    checked={parseInt(data.status) === 1}
                    value={data.id}
                    onChange={onChangeActive}
                    label="Afișare pe site"
                    className="text-success text-bold"
                />
                <Form.Text className="text-muted">
                    Doar paginile active sunt afisate pe site
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formActiv">
                <Form.Label>Afișare în carusel in urmatoarele pagini</Form.Label>
                <Form.Text className="small text-dark">
                {pages
                    .filter( p => !!p.path)
                    .map((p) => (
                        <Form.Check
                            key={p.id}
                            type="switch"
                            label={p.path==='home'?(<em>HOME-PAGE {p.title}</em>):p.title}
                            checked={data.carouselPages.includes(parseInt(p.id))}
                            value={p.id}
                            onChange={onChangeCarousel}
                            className="pl-5"
                        />
                ))}
                </Form.Text>
                <Form.Text className="text-muted">
                    In carusel se va afisa imaginea, titlul, subtitlul si sumarul.
                    Dacă există și un URL, se va afișa toată pagina la click pe carousel.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTitle">
                <Form.Label>Titlu</Form.Label>
                <Form.Control type="text" placeholder="Titlul paginii"
                    value={data.title}
                    onChange={onChangeTitle} />
                <Form.Text className="text-muted">
                    Titlul paginii, apare la vizualizarea acesteia
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formTitle">
                <Form.Label>Sub-titlu</Form.Label>
                <Form.Control type="text" placeholder="Subtitlu"
                    value={data.subtitle}
                    onChange={onChangeSubTitle} />
                <Form.Text className="text-muted">
                    Subtitlul paginii, apare imediat sub titlu, sau in carusel
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formContent">
                <Form.Label>Sumar</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="sumar..."
                    value={sumar}
                    onChange={onChangeSumar} />
                <Form.Text className="text-muted">
                    Sumar al paginii, apare in casete, carusel sau in deschiderea paginii
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>Imagine</Form.Label>
                <Form.Control type="file" onChange={onChangeFile} />
                <Form.Text className="text-muted">
                    {!!data.image &&
                        <>
                            Imaginea curentă:
                            <img className="mt-1" src={data.image} height="40px" alt="Imaginea curenta"/>
                            <br />
                        </>}
                    Incărcați imaginea la rezoluția cea mai bună - se va scala automat în funcție de locul în care apare.
                    Imaginile din carusel trebuie să aibă aceeași dimensiune pentru consistență.
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMenu">
                <Form.Label>Link meniu</Form.Label>
                <Form.Control type="text" placeholder="Link meniu"
                    value={data.menu}
                    onChange={onChangeMenu} />
                <Form.Text className="text-muted">
                    Linkul din meniu, apare in bara de navigatie
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMenu">
                <Form.Label>Ordine</Form.Label>
                <Form.Control type="number" placeholder="Ordine meniu"
                    value={data.menuOrder}
                    onChange={onChangeMenuOrder} />
                <Form.Text className="text-muted">
                    Ordinea din meniu sau carousel
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formUrl">
                <Form.Label>URL</Form.Label>
                <Form.Control type="text" placeholder="URL"
                    value={data.path}
                    onChange={onChangeUrl} />
                <Form.Text className="text-muted">
                    URL-ul paginii, apare in bara de adresa sau la click pe carousel sau casetă-block.
                    <br />Prima pagina este URL-ul special "<strong style={{ color: 'orange' }}>home</strong>"
                </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBlock">
                <Form.Label>Nume bloc</Form.Label>
                <Form.Control type="text" placeholder=""
                    value={data.block}
                    onChange={onChangeBlock} />
                <Form.Text className="text-muted">
                    Opțional: numele blocului (casetei) din care această pagină face parte.
                    Blocurile (casetele) se pot insera în conținutul principal <em>din alte pagini</em>
                    folosind secvența <strong>~</strong><em>NUME-BLOC</em><strong>~</strong>
                </Form.Text>
            </Form.Group>


            <Form.Group className="mb-3" controlId="formContent">
                <Form.Label>Continut</Form.Label>
                <Form.Control as="textarea" rows={10} placeholder="text..."
                    value={text}
                    onChange={onChangeContent} />
                <Form.Text className="text-muted">
                    Continutul paginii, apare la afișarea completă a paginii, la accesarea linkului URL.
                    <br />
                    Blocuri posibile: <span className="text-dark">{
                        Object.keys(blocks).map((b) => (<span key={b}>~{b}~ {'    '}</span>))
                    }</span>
                    <br />
                    Mini-carouse-uri posibile: <span className="text-dark">{
                        Object.keys(miniCarousels).map((c) => (<span key={c}>%{c}% {'    '}</span>))
                    }</span>
                </Form.Text>
            </Form.Group>

            <Button variant="outline-primary" className="mr-5" onClick={goBack}><IoMdArrowBack /> Înapoi</Button>{' '}
            <Button variant="success" className="ml-5" onClick={savePage}><AiFillSave />Salvează</Button>
            {currentUser.roles.includes("pagini.delete") && (<AdminPageDelete page={data} />)}
        </Form>
    )
}