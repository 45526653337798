
import { Container, Button, Col, Row, Badge } from "react-bootstrap";
import { BsCartCheckFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { ApiGet, ApiPost } from "services/ApiService";
import { setError } from "store/msgStore";
import moment from "moment";
import CartFacturare from "./CartFacturare";
import ErrorMessage from "components/common/ErrorMessage";
import CartLivrare from "./CartLivrare";
import CartProducts from "./CartProducts";

/*
https://react-bootstrap-table.github.io/react-bootstrap-table2/
*/

export default function CartPage({ currentUser }) {
    const cart = useSelector((storePersisted) => storePersisted.cart.items);
    const locationId = useSelector((storePersisted) => storePersisted.cart.locationId);
    
    const [location, setLocation] = useState([]);
    
    const dispatch = useDispatch();
    
    useEffect(() => {
        ApiGet('/locations', (data) => { setLocation(data[0]) }, (error) => { dispatch(setError(error)); });
    }, [locationId, dispatch])
    
    const sendOrder = () => {
        console.log("SEND ORDER!")
        let payloadProducts = [];
        let totalDiscount = 0;
        cart.forEach((item) => {
            let discount = 0
            if (!!item.product.discount) {
                discount = item.product.discount * item.quantity;
            }
            let payloadProduct = {
                "ID" : item.product.codExtern,
                "Observatii" : "",
                "Cant" : item.quantity,
                "UM" : item.product.um,
                "Pret" : item.total.toFixed(2),
                "discount":  discount
            };
            totalDiscount += discount;
            payloadProducts.push(payloadProduct);
        });
        
        console.log("PayloadProduct", payloadProducts);
        console.log("Total discount", totalDiscount);
        
        /* daca nu este completat NrDoc, 
        serverul genereaza automat numere unice dintrun carnet setat in WME, 
        care are bifa „Vizibil pentru aplicatii Online
        */
        let nrDoc = parseInt(Date.now()/1000);
  
        let date = moment(Date.now()).format("DD.MM.YYYY");
        
        // adresa de livrare se pune la observatii, ca poate diferi
        let adresa = currentUser.meta.denumire + ", "
                        + currentUser.meta.codFiscal + ", "
                        + currentUser.meta.registruComert + ", "
                        + location.name + ", " 
                        + location.address + ", "
                        + location.postalcode + ", "
                        + location.city + ", "
                        + location.county + ", "
                        + location.phone + ", "
                        + location.email;
    
        //cod subunit din NSubunit; poate fi consultat prin functia GetListaSubunitati
        /*
		$mag = array(1 => '2', 2 => '104');
        */
        let idShop = 2; // TODO clarify!
        
        let idClient = 0;
        if (!!currentUser.meta.codFiscal) {
            idClient = currentUser.meta.codFiscal;
        }
        
        let payload = {
			"NrDoc" 		: nrDoc, 			
            "SerieDoc" 		: "SITE",
			"DataDoc"		: date,
			"NrInreg"		: nrDoc,
			"IDClient"		: idClient,
			"Locatie"		: 'Sediu firma', // TODO: get from parteneri
			"Observatii"	: adresa,
			"Moneda"		: "Lei",	
			"PretCuAmanuntul"	: "NU",
			"CodSubunitate"		: idShop,
			"Storno"			: "NU",
			"Items"			: payloadProducts
        };
        console.log("ORDER", payload);
        ApiPost("/order", 
                payload,
                (data) => {
                    confirm(data);
                },
                (error) => {
                    dispatch(setError(error)); 
                })
    }
    
    const confirm = (data) => {
        console.log("ORDER confirmed!", data);
        // TODO: cleanup cart
    }
    
    return (
        <>
            <Container fluid className="ml-3 mt-3 mb-5 w-100" style={{ minHeight: "90vh" }}>
                <Row className="w-100">
                    <Col md={1} className="d-none d-sm-block" style={{ textAlign: 'right' }}>
                    <span style={{ fontSize:'1.8em'}} className='text-primary'><BsCartCheckFill /></span>
                    </Col>
                    <Col md={11} sm={12}>
                        <h1>Comandă</h1>
                        <p className="text-muted">Comanda este pregătită pentru a fi trimisă.</p>
                    </Col>
                </Row>
                <Row className="w-100">
                    <Col md={1} className="d-none d-sm-block" style={{ textAlign: 'right' }}>
                        <Badge pill size="lg">1</Badge>
                    </Col>
                    <Col md={11} sm={12}>
                        <CartProducts />
                    </Col>
                </Row>
                <Row>
                    <Col md={1} className="d-none d-sm-block" style={{ textAlign: 'right' }}>
                        <Badge pill size="lg">2</Badge>
                    </Col>
                    <Col md={11} sm={12}>
                        <CartFacturare currentUser={currentUser} />
                    </Col>
                </Row>
                <Row>
                    <Col md={1} className="d-none d-sm-block" style={{ textAlign: 'right' }}>
                        <Badge pill size="lg">3</Badge>
                    </Col>
                    <Col md={11} sm={12}>
                        <CartLivrare />
                    </Col>
                </Row>
                <Row>
                    <Col md={1} className="d-none d-sm-block" style={{ textAlign: 'right' }}>
                        <Badge pill size="lg">5</Badge>
                    </Col>
                    <Col md={11} sm={12}>
                        <h5>Finalizare</h5>
                        <p className="text-muted">Înainte de a trimite comanda, vă rugăm să verificați corectitudinea datelor.</p>
                        <ErrorMessage key="errorMessageKey" />
                        <Button variant="success" className="w-100" size="lg" onClick={sendOrder} disabled={!currentUser.meta.codFiscal} >Trimite comanda</Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}