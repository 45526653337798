import { useDispatch } from "react-redux";
import { Form, InputGroup } from "react-bootstrap";
import { updateQuantity } from "store/cartStore";

function CartPageQty({ item }) {
    let quantity = item.quantity;
    let dispatch = useDispatch();

    const onChangeQuantity = (e) => {
        if (!!e.target.value) {
            let q = parseInt(e.target.value);

            dispatch(updateQuantity({ productId: item.product.id, boxQty: item.boxQty, quantity: q }));

            console.log("Quantity changed", item.product.id, q, item.boxQty)
            //setQuantity(q);
        }
    }

    /* Select all to allow easy add */
    const onFocusQuantity = (event) => {
        const inputNode = event.target;
        inputNode.setSelectionRange(0, inputNode.value.length);
    }

    return (
        <>
            {!!item && (item.boxQty === 1) && (<Form.Control
                key={item.product.id}
                className="qty-input"
                onChange={onChangeQuantity}
                onFocus={onFocusQuantity}
                id={`add-to-cart-${item.product.id}`}
                type="text"
                defaultValue={quantity}
                size="lg"
            />)}

            {!!item && (item.boxQty > 1) && (
                <InputGroup>
                    <Form.Control width={140}
                        key={`bulk-${item.product.id}-${item.boxQty}`}
                        className="qty-input"
                        onChange={onChangeQuantity}
                        onFocus={onFocusQuantity}
                        id={`add-to-cart-${item.product.id}`}
                        type="text"
                        defaultValue={quantity}
                        size="lg"
                    />
                    <Form.Control
                        key={`bulkTotal-${item.product.id}-${item.boxQty}`}
                        className="qty-input"
                        onChange={onChangeQuantity}
                        onFocus={onFocusQuantity}
                        id={`add-to-cart-${item.product.id}-${item.boxQty}-all`}
                        type="text"
                        value={quantity * item.boxQty}
                        size="lg"
                        disabled={true}
                    />
                </InputGroup>
            )}
        </>
    )

}

export default CartPageQty