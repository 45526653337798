import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setError, setTempConfirmation } from "store/msgStore";
import { ApiPut } from "services/ApiService";
import { MdExpandMore, MdChevronRight, MdExpand } from "react-icons/md";

import CheckboxTree from 'react-checkbox-tree';



export default function AdminUserTypesEditProducatoriAccess({ userType }) {
    const [nodesManufacturers, setNodesClase] = useState([]);

    const manufacturers = useSelector((store) => store.products.producatori);

    const dispatch = useDispatch();

    const prepareTree = () => {
        if (!manufacturers) {
            return;
        }

        let tree = Object.keys(manufacturers)
            .map((key) => {
                let node = { value: manufacturers[key].id, label: manufacturers[key].display }
                //tree.push(node);
                return node;
            })
            .sort((a, b) => { return a.label.localeCompare(b.label) });

        setNodesClase(tree);
    }

    useEffect(() => {
        console.log("userType", userType);
        if (!!userType.prodAccess) {
            console.log("checked", userType.prodAccess.map(r => '' + r));
            setChecked(userType.prodAccess.map(r => '' + r));
        }
    }, [userType]);

    useEffect(() => {
        // render
    }, []);

    useEffect(() => {
        prepareTree();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manufacturers]);

    useEffect(() => {
        //render
    }, [userType, nodesManufacturers]);


    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const onCheck = (checked) => {
        console.log("userType", userType);
        console.log("setChecked", checked)
        setChecked(checked);

        let payload = { prodAccess: checked };

        ApiPut('/user-type-prod/' + userType.id, payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Accesul la producatori a fost modificat cu success."))
                prepareTree();
            },
            (error) => {
                dispatch(setError(error));
            });
    }

    /* future for drag/drop: https://rct.lukasbach.com/docs/guides/uncontrolled-environment */
    /* https://github.com/jakezatecky/react-checkbox-tree */
    return (
        <>
            <Alert variant="light text-muted small py-1">Producătorii la care este permis accesult. Doar produsele aparținând de acești producători vor fi afișate în rezultatele căutării.</Alert>
            <CheckboxTree
                nodes={nodesManufacturers}
                checked={checked}
                expanded={expanded}
                onCheck={onCheck}
                onExpand={(expanded) => setExpanded(expanded)}
                nativeCheckboxes={true}

                icons={{
                    // check: <span className="rct-icon rct-icon-check" />,
                    //  uncheck: <span className="rct-icon rct-icon-uncheck" />,
                    //  halfCheck: <span className="rct-icon rct-icon-half-check" />,
                    expandClose: (<MdChevronRight style={{ width: '20px', height: '20px', marginTop: '8px' }} />),
                    expandOpen: (<MdExpandMore style={{ width: '20px', height: '20px', marginTop: '8px' }} />),
                    expandAll: (<MdExpand style={{ width: '20px', height: '20px', marginTop: '8px' }} />),
                    collapseAll: (<MdExpand style={{ width: '20px', height: '20px', marginTop: '8px' }} />),
                    parentClose: (<></>),
                    parentOpen: (<></>),
                    leaf: (<></>),
                }}
            />


        </>

    );
}