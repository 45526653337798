
import { Routes, Route } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import AdminSidebar from "./AdminSidebar";

import ErrorMessage from "components/common/ErrorMessage";
import TemporaryMessage from "components/common/TemporaryMessage";
import Loading from "components/common/Loading";

import AdminCatalogImport from "./catalog/AdminCatalogImport";
import AdminStockImport from "./catalog/AdminStockImport";
import AdminPanel from "./AdminPanel";
import AdminSimbolClase from "./catalog/AdminSimbolClase";
import AdminClase from "./catalog/AdminClase";
import AdminDepozite from "./catalog/AdminDepozite";
import AdminProducatori from "./catalog/AdminProducatori";
import AdminParteneri from "./parteneri/AdminParteneri";
import AdminParteneriImport from "./parteneri/AdminParteneriImport";
import AdminUsers from "./users/AdminUsers";
import AdminUser from "./users/AdminUser";
import AdminUserTypesClase from "./user-types-clase/AdminUserTypesClase";

import { FaWarehouse, FaRegHandshake, FaUsersCog } from "react-icons/fa";
import { MdOutlinePrecisionManufacturing, MdOutlineCategory, MdOutlineCurrencyExchange } from "react-icons/md";
import { BiCarousel, BiCategoryAlt } from "react-icons/bi";
import { CgUserList } from "react-icons/cg";
import { FaCogs } from "react-icons/fa";
import { TbPackageImport, TbPremiumRights, TbTableImport } from "react-icons/tb";
import { BsFillMenuButtonWideFill, BsJournalText } from "react-icons/bs";
import { RiUserReceived2Line } from "react-icons/ri";
import AdminUserTypesClaseEdit from "./user-types-clase/AdminUserTypeClase-Edit";
import AdminProducts from "./catalog/AdminProducts";
import AdminPages from "./content/pages/AdminPages";
import AdminPage from "./content/pages/AdminPage";
import AdminMiniCarousels from "./content/mini-carousel/AdminMiniCarousels";
import AdminMiniCarousel from "./content/mini-carousel/AdminMiniCarousel";
import { useEffect } from "react";
import { loadSimbolClase } from "store/productsReducer";
import { ApiGet } from "services/ApiService";
import { useDispatch } from "react-redux";
import { setError } from "store/msgStore";
import AdminUserTypesProd from "./user-types-prod/AdminUserTypesProd";
import AdminUserTypesProdEdit from "./user-types-prod/AdminUserTypeProd-Edit";
import { AiOutlineApi } from "react-icons/ai";
import AdminMentorRest from "./mentor/AdminMentorRest";
import AdminLogs from "./mentor/AdminLogs";
import AdminOrders from "./orders/AdminOrders";
import { BsCashCoin } from "react-icons/bs";
import AdminOrdersImport from "./orders/AdminOrdersImport";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import AdminFacturi from "./orders/AdminFacturi";
import MenuList from "./menu/AdminMenu";


/* https://react-icons.github.io/react-icons */
const adminRoles = [
    {
        icon: (<BsCashCoin />),
        path: '/admin/orders',
        privilege: 'orders.admin',
        title: 'Comenzi',
        text: "Vezi comenzile si starea acestora",
    },
    {
        icon: (<LiaFileInvoiceDollarSolid />),
        path: '/admin/invoices',
        privilege: 'invoices.admin',
        title: 'Facturi',
        text: "Vezi facturile si starea acestora",
    },
    {
        section: 'Administrare Catalog'
    },
    {
        icon: (<FaCogs />),
        path: '/admin/produse',
        privilege: 'produse.admin',
        title: 'Administrare produse',
        text: "Administreaza produsele si informatiile suplimentare",
    },
    {
        icon: (<FaWarehouse />),
        path: '/admin/depozite',
        privilege: 'producatori.admin',
        title: 'Administrare depozite',
        text: "Administreaza depozitele si maparea cu gestiunile din Win Mentor",
    },
    {
        icon: (<MdOutlinePrecisionManufacturing />),
        path: '/admin/producatori',
        privilege: 'producatori.admin',
        title: 'Administrare producatori',
        text: "Administreaza modul de afisare a producatorilor din Win Mentor",
    },
    {
        icon: (<MdOutlineCategory />),
        path: '/admin/clase',
        privilege: 'clase.admin',
        title: 'Administrare clase',
        text: "Administreaza modul de afisare a tipurilor de produs din Win Mentor (clase)",
    },
    {
        icon: (<BiCategoryAlt />),
        path: '/admin/simbol-clase',
        privilege: 'simbolClase.admin',
        title: 'Administrare simbol clase',
        text: "Administreaza modul de afisare a categoriilor din Win Mentor (simbol clase)",
    },
    {
        section: 'Administrare Utilizatori'
    },
    {
        icon: (<CgUserList />),
        path: '/admin/users',
        privilege: 'users.admin',
        title: 'Utilizatori B2B',
        text: "Administreaza utilizatorii si drepturile",
    },
    {
        icon: (<FaRegHandshake />),
        path: '/admin/parteneri',
        privilege: 'parteneri.admin',
        title: 'Parteneri',
        text: "Vizualizeaza setarile de parteneri",
    },
    {
        icon: (<TbPremiumRights />),
        path: '/admin/user-types-clase',
        privilege: 'user.types.admin',
        title: 'Tipuri utilizator - clase',
        text: "Administreaza accesul la clase de produse si discounturile pe clase",
    },
    {
        icon: (<FaUsersCog />),
        path: '/admin/user-types-prod',
        privilege: 'user.types.admin',
        title: 'Tipuri utilizator - producători',
        text: "Administreaza accesul la producători",
    },
    {
        section: 'Sincronizare WinMentor'
    },
    {
        icon: (<MdOutlineCurrencyExchange />),
        path: '/admin/orders/import',
        privilege: 'orders.admin',
        title: 'Import comenzi',
        text: 'Sincronizare comenzi cu WinMentor'
    },
    {
        icon: (<TbPackageImport />),
        path: '/admin/catalog/import',
        privilege: 'catalog.admin',
        title: 'Import catalog',
        text: "Sincronizare cu produsele  WinMentor",
    },
    {
        icon: (<TbTableImport />),
        path: '/admin/catalog/stock/import',
        privilege: 'stock.admin',
        title: 'Import stocuri',
        text: 'Sincronizare stocuri cu WinMentor'
    },
    {
        icon: (<RiUserReceived2Line />),
        path: '/admin/parteneri/import',
        privilege: 'parteneri.import',
        title: 'Import parteneri',
        text: 'Sincronizare parteneri cu WinMentor'
    },
    {
        section: 'Utilizatori autentificati'
    },
    {
        icon: (<RiUserReceived2Line />),
        path: '/admin/menu',
        privilege: 'menu.admin',
        title: 'Administrare meniu',
        text: 'Administrare meniu produse'
    },
    {
        section: 'Site public'
    },
    {
        icon: (<BsFillMenuButtonWideFill />),
        path: '/admin/pagini',
        privilege: 'pagini.admin',
        title: 'Pagini',
        text: 'Pagini publice prezentare'
    },
    {
        icon: (<BiCarousel />),
        path: '/admin/mini-carousel',
        privilege: 'mini-carousel.admin',
        title: 'Mini-carousel',
        text: 'Mini-carousel parteneri, producatori, etc'
    },
    {
        section: 'Advanced'
    },
    {
        icon: (<AiOutlineApi />),
        path: '/admin/mentor-rest',
        privilege: 'mentor.admin',
        title: 'WinMentor REST-Server',
        text: 'Verificare status WinMentor REST-Server si setari'
    },
    {
        icon: (<BsJournalText />),
        path: '/admin/logs',
        privilege: 'mentor.admin',
        title: 'Logs',
        text: 'Vizualizare loguri'
    },
]

function Admin({ currentUser }) {
    const dispatch = useDispatch();
    
    useEffect(()=>{
            ApiGet('/catalog/clase/simboluri', 
                (data) => { dispatch(loadSimbolClase(data)) }, 
                (error) => { dispatch(setError(error)); });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //let columnsPerRow = 4;
    
    if (!currentUser) {
        return (
            <></>
        );
    }

    return (
        <>
            <Container fluid className="ml-0 w-100 px-0" style={{ minHeight: "90vh" }}>
                <Row className="w-100">

                    <Col xxl={2} xl={2} lg={3} md={3} sm={12} xs={12} className="pt-3 mr-3 bg-dark ml-0 px-0" >
                        <AdminSidebar currentUser={currentUser} />
                    </Col>

                    <Col xxl={10} xl={10} lg={9} md={9} sm={12} xs={12} className="pb-5">
                        <TemporaryMessage />
                        <ErrorMessage />
                        <Loading />

                        <Routes>
                            <Route path="" index element={<AdminPanel currentUser={currentUser} />} />

                            <Route path="produse" element={<AdminProducts currentUser={currentUser} />} />
                            <Route path="depozite" element={<AdminDepozite currentUser={currentUser} />} />
                            <Route path="producatori" element={<AdminProducatori currentUser={currentUser} />} />
                            <Route path="clase" element={<AdminClase currentUser={currentUser} />} />
                            <Route path="simbol-clase" element={<AdminSimbolClase currentUser={currentUser} />} />
                            <Route path="catalog/import" element={<AdminCatalogImport currentUser={currentUser} />} />
                            <Route path="catalog/stock/import" element={<AdminStockImport currentUser={currentUser} />} />

                            <Route path="orders" element={<AdminOrders currentUser={currentUser} />} />
                            <Route path="orders/import" element={<AdminOrdersImport currentUser={currentUser} />} />

                            <Route path="invoices" element={<AdminFacturi currentUser={currentUser} />} />

                            <Route path="parteneri" element={<AdminParteneri currentUser={currentUser} />} />
                            <Route path="parteneri/import" element={<AdminParteneriImport currentUser={currentUser} />} />

                            <Route path="users" element={<AdminUsers currentUser={currentUser} />} />
                            <Route path="users/:id" element={<AdminUser currentUser={currentUser} />} />

                            <Route path="user-types-clase" element={<AdminUserTypesClase currentUser={currentUser} />} />
                            <Route path="user-types-clase/:id" element={<AdminUserTypesClaseEdit currentUser={currentUser} />} />

                            <Route path="user-types-prod" element={<AdminUserTypesProd currentUser={currentUser} />} />
                            <Route path="user-types-prod/:id" element={<AdminUserTypesProdEdit currentUser={currentUser} />} />

                            <Route path="menu" element={<MenuList currentUser={currentUser} />} />

                            {/* <Route path="users/:id" element={<AdminUser currentUser={currentUser} />} /> */}
                            <Route path="pagini" element={<AdminPages currentUser={currentUser} />} />
                            <Route path="pagini/:pageId" element={<AdminPage currentUser={currentUser} />} />
                            
                            <Route path="mini-carousel" element={<AdminMiniCarousels currentUser={currentUser} />} />
                            <Route path="mini-carousel/:carId" element={<AdminMiniCarousel currentUser={currentUser} />} />
                            
                            <Route path="mentor-rest" element={<AdminMentorRest currentUser={currentUser} />} />
                            <Route path="logs" element={<AdminLogs currentUser={currentUser} />} />
                          
                        </Routes>


                    </Col>
                </Row>
            </Container>
        </>
    );
}

export { Admin, adminRoles };
