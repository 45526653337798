import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { login } from 'services/AuthService';
import { Card, Row, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";

function LoginForm() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const onSubmit = data => {
        setMessage('');
        setLoading(true);

        login(data.username, data.password)
            .then(() => {
                window.location.href = '/profile';
            })
            .catch(error => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setLoading(false);
                setMessage("Eroare la autentificare: " + resMessage);
            });
    };

    return (
        <Card style={{ background: 'linear-gradient(to bottom, rgba(255, 165, 0, .75) 0%,  rgba(204, 120, 34, .75) 50%, rgba(179, 105, 33, .75) 100%)', height: '90%' }}>
            <Card.Body style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between', // Space between title/content and button
            }}>
                <Card.Title className="mb-3" style={{ color: 'black', fontWeight: 'bold', fontSize: '1.5em' }}>Conectați-vă acum</Card.Title>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group">
                        <label htmlFor="username" style={{ color: 'white' }}>Adresa de email</label>
                        <input
                            type="text"
                            className="form-control"
                            name="username"
                            {...register('username', { required: 'Vă rugăm introduceți o valoare!' })}
                            style={{ opacity: 0.85 }}
                        />
                        {errors.username && (
                            <div className="alert alert-danger" role="alert">
                                {errors.username.message}
                            </div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="password" style={{ color: 'white' }}>Parola</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            {...register('password', { required: 'Vă rugăm introduceți o valoare!' })}
                            style={{ opacity: 0.85 }}
                        />
                        {errors.password && (
                            <div className="alert alert-danger" role="alert">
                                {errors.password.message}
                            </div>
                        )}
                    </div>

                    <Row className="d-flex justify-content-between align-items-center mt-3">
                        <Col xs="auto">
                            <div className="form-group mt-3 text-center">
                                <button
                                    className="btn btn-light btn-block"
                                    style={{ backgroundColor: 'orange', borderColor: 'black' }}
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <span className="spinner-border spinner-border-sm"></span>
                                    ) : (
                                        <span>Conectați-vă</span>
                                    )}
                                </button>
                            </div>
                        </Col>
                        <Col xs="auto">
                            <div className="form-group mt-3 text-center small">
                                <NavLink style={{ color: 'grey', textDecoration: 'none' }} to="/recuperare-parola">Recuperare parolă</NavLink>
                            </div>
                        </Col>
                    </Row>

                    {message && (
                        <div className="form-group mt-1">
                            <div className="alert alert-danger" role="alert">
                                {message}
                            </div>
                        </div>
                    )}
                </form>
            </Card.Body>
        </Card>
    );
}

export default LoginForm;
