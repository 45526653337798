import { Container, Row, Col } from "react-bootstrap";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import CursBNR from "./common/Curs";

export default function Footer({ currentUser }) {
    return (
        <Container id="footer" fluid className="ml-3 w-100 bg-dark mt-0 pb-3">
            <Row className="mx-3">
                <Col xs={12} sm={5} md={6} className="text-light small text-left pt-3">
                    <h5>Cariere</h5>
                    <p>Consideri că ai aptitudinile necesare pentru a face parte din echipa Rolling?
                        Îți dorești un loc de muncă într-o companie de succes?<br/>
                        <NavLink to="cariere">&raquo; Vezi care sunt posturile disponibile!</NavLink>
                    </p>


                </Col>
                <Col xs={12} sm={4} md={4}  className="text-light small text-left pt-3">
                    <h5>Utile</h5>
                    <ul>
                        <li><NavLink to="contact">Contact</NavLink></li>
                        <li><NavLink to="termene-conditii">Termene și condiții</NavLink></li>
                        <li><NavLink to="gdpr">GDPR</NavLink></li>
                        <li><NavLink to="parteneri">Vreau să devin partener</NavLink></li>
                    </ul>

                    <a href="https://anpc.ro/" target="_blank" rel="noreferrer">Autoritea Națională pentru Protecția Consumatorilor</a>
                </Col>
                <Col xs={12} sm={3} md={2}  className="text-light small text-left pt-3">
                    <h5 className="mb-1">Urmărește-ne</h5>
                    <a className="social" href="https://www.facebook.com/rollingtimisoara"><BsFacebook /></a>
                    <a className="social" href="https://www.instagram.com/rollingtimisoara"><BsInstagram /></a>
             
                    {!!currentUser && <CursBNR />}
                </Col>
            </Row>

            <Row>
                <Col className="text-muted text-center pt-3 copyright">
                    &copy; Rolling System 1997 - {new Date().getFullYear()} | Toate drepturile rezervate
                </Col>
            </Row>
        </Container>
    )
}