import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setError, clearError, setTempConfirmation } from "store/msgStore";
import { ApiGet, ApiPost, ApiDel } from "services/ApiService";
import BootstrapTable from "react-bootstrap-table-next";
import { setLoading } from "store/msgStore";
import { Button, Col, Row } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import { Form } from "react-bootstrap";


export default function AdminProductFiles({ product }) {
    const [files, setFiles] = useState([]);
    const [newFile, setNewFile] = useState(null);

    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/catalog/products/files/' + product.codArticol, (data) => { setFiles(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // render
    }, [files]);

    const doDelete = (id) => {
        console.log('delete bulk', id)

        ApiDel('/catalog/products/files/' + id,
            (dataOk) => {
                console.log("Delete OK", dataOk);
                dispatch(setTempConfirmation("Valoarea a fost stearsa"))
                setFiles(dataOk);
            },
            (error) => {
                dispatch(setError(error));
            });
    }

    const deleteFormatter = (cell, row) => {
        if (row.id === 0) {
            return (<>-</>);
        }

        return (
            <Button
                onClick={() => doDelete(row.id)}
                style={{ padding: 0 }}
                variant="link" size="sm" title="Șterge" className="text-danger">
                <MdDeleteForever />
            </Button>
        )
    }

    function linkFormatter(cell, row) {
        if (row.url) {
            return (
                <a href={`${row.url}`} rel="noreferrer" target="_blank">{`${cell}`}</a>
            );
        }

        return (
            cell
        );
    }

    const columns = [
        {
            dataField: 'name',
            text: 'Nume',
            editable: false,
            formatter: linkFormatter
        },
        {
            dataField: 'delete',
            isDummyField: true,
            text: 'Sterge',
            editable: false,
            formatter: deleteFormatter,
            headerStyle: { width: '10%' }
        }
    ]

    function onSaveNewFile(e) {
        e.preventDefault();
        dispatch(setLoading(true));

        const formData = new FormData();

        formData.append('file', newFile);

        ApiPost('/catalog/products/files/' + product.codArticol,
            formData,
            (data) => {
                dispatch(setLoading(false));
                dispatch(setTempConfirmation("Fisierul a fost salvat"));
                setFiles(data);
            },
            (error) => {
                dispatch(setLoading(false));
                dispatch(setError(error));
            });
    }

    const onChangeFile = (e) => {
        console.log("File", e.target.files);
        setNewFile(e.target.files[0]);
    }

    const rowClass = 'align-items-center';

    return (
        <>
            {!!files && <BootstrapTable
                hover
                keyField='id'
                data={JSON.parse(JSON.stringify(files))}
                columns={columns}
                rowClasses={rowClass}
                condensed
            />}

            <Row className="mb-3">
                <Form.Label column="sm" sm={12} md={4} lg={2} xl={2} className="small">Adaugă fisa noua: </Form.Label>
                <Col>
                    <Form.Control type="file" size="sm" className="mr-0" onChange={onChangeFile} />
                </Col>
                <Col sm={1}>
                    <Button variant="outline-dark" className="ml-0" size="sm" onClick={onSaveNewFile}>+</Button>
                </Col>
            </Row>
        </>
    )
}