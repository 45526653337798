import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setError, setTempConfirmation } from "store/msgStore";
import { ApiPut } from "services/ApiService";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';




export default function AdminUserTypesEditClaseDiscount({ userType, refresh }) {
    const [data, setData] = useState([]); // need to do a deep copy

    const simbolClase = useSelector((store) => store.products.simbolClase);

    const dispatch = useDispatch();

    const prepareData = () => {
        let newData = []
        console.log("userType ", userType);
        if (!!userType.claseAccess) {
            Object.keys(simbolClase).forEach((key) => {

                let hasAccess = userType.claseAccess.find(i => i === key);
                let discount = '';

                if (!!userType.discounts) {
                    let available = userType.discounts.find(i => i.id === key);
                    if (!!available) {
                        discount = available.discount;
                    }
                }

                //console.log("find for ", key, hasAccess, discount);
                if (hasAccess) {
                    newData.push(
                        {
                            ...simbolClase[key],
                            discount: discount
                        }
                    )
                }
            }
            );
        }
        setData(newData);
    }

    useEffect(() => {
        // render
    }, []);

    useEffect(() => {
        //render
    }, [data]);

    useEffect(() => {
        prepareData();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userType, simbolClase]);



    const columns = [
        {
            dataField: 'id',
            text: '#',
            sort: true,
            editable: false,
            headerStyle: { width: '10%' }
        },
        {
            dataField: 'display',
            text: 'Clasa Simbol',
            sort: true,
            editable: false
        }, {
            dataField: 'discount',
            text: 'Discount',
            sort: true,
            editable: true,
            /*validator: (newValue, row, column) => {
                if (!(newValue)) {
                    return {
                        valid: false,
                        message: 'Introduceti o valoare'
                    };
                }
                return true;
            }*/
        }]

    const defaultSorted = [{
        dataField: 'name', // if dataField is not match to any column you defined, it will be ignored.
        order: 'asc' // desc or asc
    }];


    function beforeSaveCell(oldValue, newValue, row, column, done) {
        if (oldValue === newValue) {
            console.log("same value, ignore");
            done(false);
            return;
        }

        newValue = newValue.replace('%', '');
        newValue = parseFloat(newValue);
        console.log("save", newValue, row.id)

        let payload = { idClasaSimbol: row.id, discount: newValue }

        ApiPut('/user-type-clase/' + userType.id, payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Valoarea [" + newValue + "] a fost salvata cu success."))
                //prepareData();
                refresh();
                done(true)
            },
            (error) => {
                dispatch(setError(error));
                done(false)
            });

        return { async: true };
    }



    return (
        <>

            <Alert variant="light text-muted small py-1">Introduceti discountul (in procente, fara minus) pentru fiecare clasa de produse.</Alert>
            {(<BootstrapTable
                hover
                keyField='id'
                data={JSON.parse(JSON.stringify(data))}
                columns={columns}
                defaultSorted={defaultSorted}
                cellEdit={cellEditFactory({
                    mode: 'dbclick',
                    beforeSaveCell
                })}
                pagination={paginationFactory()}
            />
            )}


        </>

    );
}