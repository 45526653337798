import { ApiGet } from "services/ApiService";
import { useEffect, useState } from "react";
import { loadCurs } from "store/productsReducer";
import { useDispatch } from "react-redux";

export default function CursBNR() {
    const [curs, setCurs] = useState({});

    var dispatch = useDispatch();

    const refresh = () => {
        ApiGet("/curs",
            (data) => {
                setCurs(data);
                dispatch(loadCurs(data))
            },
            (error) => setCurs({}));
    }

    useEffect(() => {
        refresh();
        setInterval(refresh, 1000 * 60 * 15); // check curs every 15 mins
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => { }, [curs]);

    return (
        <>
            {
                (!!curs && !!curs.name && !!curs.value && !!curs.timeStamp) ? (
                    <>
                        <h5 className="mt-3 mb-1">Curs BNR</h5>
                        <p>{curs.name} = {curs.value} RON
                            <br /><small className="text-muted">{curs.timeStamp}</small></p>
                    </>
                ) : (<p>Curs neactualizat!</p>)
            }
        </>
    )
}