import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ApiGet, ApiPut } from "services/ApiService";
import { setTempConfirmation, setError, clearError } from "store/msgStore";
import { Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { TbPremiumRights } from "react-icons/tb";
import { FaUsersCog } from "react-icons/fa";

export default function AdminUserEditType({ user, refresh }) {
    const [userTypesClase, setUserTypesClase] = useState([]); // need to do a deep copy
    const [userTypesProd, setUserTypesProd] = useState([]); //

    let dispatch = useDispatch();

    const refreshUserTypes = () => {
        dispatch(clearError());

        ApiGet('/user-types-clase', (data) => { setUserTypesClase(data) }, (error) => { dispatch(setError(error)); });
        ApiGet('/user-types-prod', (data) => { setUserTypesProd(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        //render
    }, [userTypesClase, userTypesProd]);

    useEffect(() => {
        refreshUserTypes();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const confirm = (data) => {
        dispatch(setTempConfirmation("Rolul a fost modificat"))
        refresh();
    }

    const onSelectChangeClasa = (e) => {
        if (!e || !e.target || !e.target.id) {
            return;
        }
        console.log("selection changed", e.target.id);

        let payload = { ...user, idUserTypeClasa: parseInt(e.target.id) }
        ApiPut('/user/' + user.id, payload, (data) => { confirm(data) }, (error) => { dispatch(setError(error)); });
    }

    const onSelectChangeProd = (e) => {
        if (!e || !e.target || !e.target.id) {
            return;
        }
        console.log("selection changed", e.target.id);

        let payload = { ...user, idUserTypeProd: parseInt(e.target.id) }
        ApiPut('/user/' + user.id, payload, (data) => { confirm(data) }, (error) => { dispatch(setError(error)); });
    }

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <TbPremiumRights /> Access clase
                            </Card.Title>
                            <Card.Text className="text-muted">
                                Utilizatorul va prelua toate drepturile și discounturile definite în acest tip de utilizator
                            </Card.Text>
                        </Card.Body>
                        <ListGroup variant="flush">
                            <Form>
                                {!!userTypesClase &&
                                    userTypesClase
                                        .filter(t => t.active)
                                        .map(t => (
                                            <ListGroup.Item key={t.id}>
                                                <Form.Check
                                                    type='radio'
                                                    id={t.id}
                                                    name="userType"
                                                    label={<><strong>{t.name}</strong><br /><small>{t.description}</small></>}
                                                    checked={user.idUserTypeClasa === t.id}
                                                    onChange={onSelectChangeClasa}
                                                />
                                            </ListGroup.Item>
                                        ))}
                            </Form>
                        </ListGroup>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                <FaUsersCog /> Access producatori
                            </Card.Title>
                            <Card.Text className="text-muted">
                                Utilizatorul va prelua toate drepturile de acces la producatori
                            </Card.Text>
                        </Card.Body>
                        <ListGroup variant="flush">
                            <Form>
                                {!!userTypesProd &&
                                    userTypesProd
                                        .filter(t => t.active)
                                        .map(t => (
                                            <ListGroup.Item key={t.id}>
                                                <Form.Check
                                                    type='radio'
                                                    id={t.id}
                                                    name="userType"
                                                    label={<><strong>{t.name}</strong><br /><small>{t.description}</small></>}
                                                    checked={user.idUserTypeProd === t.id}
                                                    onChange={onSelectChangeProd}
                                                />
                                            </ListGroup.Item>
                                        ))}
                            </Form>
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </>
    )
}