import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setError, clearError, setTempConfirmation } from "store/msgStore";
import { ApiGet, ApiPut, ApiDel } from "services/ApiService";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Button } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";


export default function AdminProductEquivalents({ product }) {
    const [eq, setEq] = useState([]);

    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/catalog/products/eq/' + product.codArticol, (data) => { setEq(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // render
    }, [eq]);


    function beforeSaveCell(oldValue, newValue, row, column, done) {
        if (oldValue === newValue) {
            console.log("same value, ignore");
            done(false);
            return;
        }

        console.log("save", newValue, row.id)

        let payload = { ...row }
        payload[column['dataField']] = newValue

        ApiPut('/catalog/products/eq/' + product.codArticol,
            payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Valoarea [" + newValue + "] a fost salvata cu success."))
                setEq(dataOk);
                done(true)
            },
            (error) => {
                dispatch(setError(error));
                done(false)
            });

        return { async: true };
    }

    const doDelete = (id) => {
        console.log('delete bulk', id)

        ApiDel('/catalog/products/eq/' + id,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Valoarea a fost stearsa"))
                setEq(dataOk);
            },
            (error) => {
                dispatch(setError(error));
            });
    }

    const deleteFormatter = (cell, row) => {
        if (row.id === 0) {
            return (<>-</>);
        }
        return (
            <Button
                onClick={() => doDelete(row.id)}
                style={{ padding: 0 }}
                variant="link" size="sm" title="Șterge" className="text-danger">
                <MdDeleteForever />
            </Button>
        )
    }

    const columns = [
        {
            dataField: 'brand',
            text: 'Brand',
            editable: true,
        },
        {
            dataField: 'eqCode',
            text: 'Cod echivalent',
            editable: true,
        },
        {
            dataField: 'delete',
            isDummyField: true,
            text: 'Sterge',
            editable: false,
            formatter: deleteFormatter,
            headerStyle: { width: '10%' }
        }
    ]

    function addNew(e) {
        e.preventDefault();
        console.log("new");
        let d = JSON.parse(JSON.stringify(eq));
        d.push({ id: 0, brand: 'Brand', eqCode: 'cod-alt' });
        setEq(d);
    }
    
    return (
        <>
            {!!eq && <BootstrapTable
                hover
                keyField='id'
                data={JSON.parse(JSON.stringify(eq))}
                columns={columns}
                cellEdit={cellEditFactory({
                    mode: 'dbclick',
                    beforeSaveCell
                })}
                condensed
            />}
            <Button size="sm" variant="outline-dark" style={{ padding: '0px 5px' }} onClick={addNew}><small>+ Adaugă cod echivalent</small></Button>
        </>
    )
}