import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Container, Button, Table, Modal, Form } from "react-bootstrap";
import { ApiDel, ApiGet, ApiPost, ApiPut } from "services/ApiService";
import { setError } from "store/msgStore";

const MenuItem = ({ menu, item, moveItem, dragItem, adjustIndent, editItem, deleteItem, addHere }) => {
  
  return (
    <>
      <tr className="menu-edit" style={{ fontWeight: item.parentId === 0 ? 'bold' : 'normal' }}>
        <td style={{
          paddingLeft: item.parentId ? 30 : 5,
          backgroundColor: item.parentId === 0 ? 'lightgrey' : 'white',

        }}>{item.name}</td>
        <td style={{ backgroundColor: item.parentId === 0 ? 'lightgrey' : 'white' }}>{item.description}</td>
        <td style={{ backgroundColor: item.parentId === 0 ? 'lightgrey' : 'white' }}>{item.clasaWeb}</td>
        <td style={{ backgroundColor: item.parentId === 0 ? 'lightgrey' : 'white', whiteSpace: 'nowrap' }}>
          {(item.parentId === 0 && <> <Button variant="success" size="sm" onClick={() => addHere(item.id)}>+</Button>{' '} </>)}

          {(item.parentId !== 0 && <>
            {/* <Button variant="secondary" size="sm" onClick={() => adjustIndent(item.id, 'left')}>◀</Button>{' '}
            <Button variant="secondary" size="sm" onClick={() => adjustIndent(item.id, 'right')}>▶</Button>{' '} */}
            <Button variant="secondary" size="sm" onClick={() => moveItem(item.id, -1)}>▲</Button>{' '}
            <Button variant="secondary" size="sm" onClick={() => moveItem(item.id, 1)}>▼</Button>{' '}
          </>)}
          <Button variant="warning" size="sm" onClick={() => editItem(item)}>Edit</Button>{' '}
          <Button variant="danger" size="sm" onClick={() => deleteItem(item.id)}>X</Button>
        </td>
        <td style={{ backgroundColor: item.parentId === 0 ? 'lightgrey' : 'white' }}>{item.id}</td>
        <td style={{ backgroundColor: item.parentId === 0 ? 'lightgrey' : 'white' }}>{item.parentId}</td>
      </tr>
      {/*
        menu.map(child => {
          console.log("child", child);
          if (child.parentId === item.id) {
            console.log("child match");
            return (
              <MenuItem key={child.id} menu={menu} item={child} moveItem={moveItem} adjustIndent={adjustIndent} editItem={editItem} deleteItem={deleteItem} />
            )
          }
        }) */}
    </>
  );
};

const MenuList = () => {
  const [menu, setMenu] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState({ id: null, name: "", description: "", parentId: null });

  let dispatch = useDispatch();
  /*
    const buildTree = (items, parentId = 0) => {
      return items
        .filter(item => item.parentId === parentId)
        .map(item => ({ ...item, children: buildTree(items, item.id) }));
    };
  */
  useEffect(() => {
    //render
    console.log("menu", menu);
  }, [menu]);

  useEffect(() => {
    ApiGet('/admin-menu', (data) => sortMenu(data), (error) => dispatch(setError(error)));
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateMenuOrder = async (items) => {
    ApiPut('/admin-menu', JSON.stringify({ items }), (data) => sortMenu(data), (error) => dispatch(setError(error)));
  };


  const menuOrder = (menuArray, tempMenu, order, parent = 0) => {
    let lastIndex = menuArray.length;
    tempMenu
      .filter(item => item.parentId === parent)
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .forEach((item) => {
        item.displayOrder = order += 10;
        menuArray[lastIndex++] = item
        menuOrder(menuArray, tempMenu, order, item.id)
        lastIndex = menuArray.length
      })
  }

  const sortMenu = (data) => {
    let menuArray = [];
    menuOrder(menuArray, data, 10)
    setMenu(menuArray);
  }

  const dragItem = (id, parentId) => {
    console.log("dragItem", id, parentId, menu)
    const updatedMenu = menu.map((item) => {
      if (item.parentId === id) {
        return { ...item, displayOrder: item.displayOrder - 15 }

      }
      if (item.id === id) {
        return { ...item, parentId: parentId }
      }
      return item
    })
    sortMenu(updatedMenu);
  }

  const moveItem = (id, direction) => {
    console.log("moveItem", id, direction, menu)

    const updatedMenu = menu.map((item, index, array) => {
      if (item.id === id && index + direction < array.length && index + direction >= 0) {
        // find previous item in sorted menu  
        let start = index + direction;
        let previousItem = array[start]; // Find the previous item
        console.log("previousItem", previousItem);

        while (previousItem.parentId === item.id && start + direction < array.length && start + direction > 0) {
          start += direction;
          previousItem = array[start]
          console.log("previousItem", start, previousItem);
        }

        if (previousItem.parentId === item.id) {
          console.log("cannot move");
          return item
        }
        if (previousItem.parentId !== item.parentId) {
          return { ...item, parentId: previousItem.id, displayOrder: previousItem.displayOrder + direction }
        } else {
          return { ...item, displayOrder: previousItem.displayOrder + direction }
        }
      }
      return item
    })
    console.log("updatedMenu", updatedMenu);
    updateMenuOrder(updatedMenu);  // Update order in the backend
  };

  const adjustIndent = (id, direction) => {
    console.log("adjustIndent", id, direction);
    const updatedMenu = menu.map((item, index, array) => {
      if (item.id === id) {
        console.log("adjustIndent found item", item);
        const previousItem = array[index - 1]; // Find the previous item
        console.log("adjustIndent previousItem", previousItem);
        const newParent = direction === 'right'
          ? previousItem.parentId
          : previousItem ? 0 : 0;
        return { ...item, parentId: newParent };
      }
      return item;
    });
    console.log("adjustIndent updated menu", updatedMenu);

    updateMenuOrder(updatedMenu);  // Update order in the backend
  }

  const handleSave = () => {
    ApiPost('/admin-menu', currentItem, (data) => {
      setShowModal(false);
      sortMenu(data);
    }, (error) => { dispatch(setError(error)); });

  };

  const editItem = (item) => {
    setCurrentItem(item);
    setShowModal(true);
  };

  const addHere = (parentId) => {
    setCurrentItem({ id: null, name: "", description: "", parentId: parentId, displayOrder: 10000 });
    setShowModal(true);
  }

  const deleteItem = async (id) => {
    ApiDel(`/admin-menu/${id}`, setMenu, (error) => { dispatch(setError(error)); });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Container>
        <h2>Menu Editor</h2>
        <Button onClick={() => { setCurrentItem({ id: null, name: "", description: "", parentId: 0 }); setShowModal(true); }} className="mb-3">Add Item</Button>
        <Table bordered style={{ condensed: 'true', fontSize: '0.8em' }}>
          <thead>
            <tr>
              <th>Nume</th>
              <th>Descriere</th>
              <th>Clasa WEB</th>
              <th>&nbsp;</th>
              <th>ID</th>
              <th>ID-parinte</th>
            </tr>
          </thead>
          <tbody>
            {menu
              .map((item) => (
                <MenuItem key={item.id} menu={menu} item={item} moveItem={moveItem} dragItem={dragItem} adjustIndent={adjustIndent} editItem={editItem} deleteItem={deleteItem} addHere={addHere} />
              ))}
          </tbody>
        </Table>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{currentItem.id ? "Edit Item" : "Add Item"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" value={currentItem.name} onChange={(e) => setCurrentItem({ ...currentItem, name: e.target.value })} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control type="text" value={currentItem.description} onChange={(e) => setCurrentItem({ ...currentItem, description: e.target.value })} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Clasa WEB</Form.Label>
                <Form.Control type="text" value={currentItem.clasaWeb} onChange={(e) => setCurrentItem({ ...currentItem, clasaWeb: e.target.value })} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Ordine afisare</Form.Label>
                <Form.Control type="text" value={currentItem.displayOrder} onChange={(e) => setCurrentItem({ ...currentItem, displayOrder: e.target.value })} />
              </Form.Group>
              <Form.Group>
                <Form.Label>Parinte</Form.Label>
                <Form.Control type="text" value={currentItem.parentId} onChange={(e) => setCurrentItem({ ...currentItem, parentId: e.target.value })} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
            <Button variant="primary" onClick={handleSave}>Save</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </DndProvider>
  );
};

export default MenuList;
