import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import PublicCarousel from "./PublicCarousel";
import { renderParagraph } from "services/ParagraphRenderService";
import { useEffect } from "react";

export default function PublicPage({ page }) {
    const pages = useSelector((store) => store.content.pages);

    const hasCarousel = () => {
        if (!pages || !page) {
            return false;
        }

        return pages
            .some(p => p.carouselPages.includes(page.id));
    }
    
    const isContactPage = () => {
        return page.path.toString().toLowerCase() === 'contact';
    }

    document.title = page.title;

    useEffect(() => { }, [pages]);

    return (
        <Container style={{ width: "75%", height: "90%" }} className="mb-5">

            <h1 className="mt-5 mb-3 bt-3" style={{ borderBottom: "5px solid orange" }}>{page.title}</h1>
            {!!page.subtitle && <h3 className="mb-3">{page.subtitle}</h3>}

            {isContactPage() ? 
            (
                <>
                    {renderParagraph(page.sumar, 0, true)}
                    <hr className="mt-2 mb-3" style={{border:"1px solid #f60"}} />
                    {page.content.split("\n").map((p, index) => {
                        return renderParagraph(p, index);
                    })}
                </>
            ) : (
               <>
                    {hasCarousel() ?
                        (!!page && <PublicCarousel currentPageId={page.id} />)
                        :
                        (!!page.image && (!hasCarousel) &&
                            <img src={page.image}
                                className="imgShadow"
                                style={{ width: "100%", height: "600px", objectFit: "cover", }}
                                alt={page.title}
                            />)}

                    {!!page.sumar && <p className="mt-2 mb-3" style={{ fontSize: "1.2em" }}><em>{page.sumar}</em></p>}

                    {page.content.split("\n").map((p, index) => {
                        return renderParagraph(p, index);
                    })}
             </>
            )}

        </Container>
    )
}
