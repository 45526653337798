import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setError, clearError } from "store/msgStore";
import { ApiGet } from "services/ApiService";

import AdminUserTypesEditProducatoriAccess from "./AdminUserType-Edit-ProdAccess";



export default function AdminUserTypesProdEdit() {
    const params = useParams(); // parameters from URL (react-router)
    const [userType, setUserType] = useState([]);

    const dispatch = useDispatch();

    const refresh = () => {
        dispatch(clearError());
        ApiGet('/user-type-prod/' + params.id, (data) => { setUserType(data) }, (error) => { dispatch(setError(error)); });
    }

    useEffect(() => {
        refresh();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /* future for drag/drop: https://rct.lukasbach.com/docs/guides/uncontrolled-environment */
    /* https://github.com/jakezatecky/react-checkbox-tree */
    return (
        <>
            <h2>{userType.name} - drepturi de acces</h2>
            <p>{userType.description}</p>
            <AdminUserTypesEditProducatoriAccess userType={userType} />
        </>

    );
}