import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setError, clearError, setLoading } from "store/msgStore";
import { ApiGet } from "services/ApiService";
import { loadMenu, searchResults, setActiveMenu } from "store/productsReducer";
import { useNavigate } from "react-router-dom";


export default function ProductMenu({ curentUser }) {
  const activeMenu = useSelector((store) => store.products.activeMenu);
  const tree = useSelector((store) => store.products.menuTree);

  const [currentPath, setCurrentPath] = useState([]);

  let dispatch = useDispatch()
  let navigate = useNavigate();


  const dispatchSearchResults = (data) => {
    dispatch(setLoading(false));
    dispatch(searchResults(data));
  }

  const searchError = (error) => {
    dispatch(setError(error));
    dispatchSearchResults([]);
  }

  const getPathIds = (selectedItem) => {
    return [selectedItem.parentId, selectedItem.id];
  }

  useEffect(() => {
    console.log("Refreshing menu");
    dispatch(clearError());

    ApiGet('/menu', (data) => { dispatch(loadMenu(data)) }, (error) => { dispatch(setError(error)) });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curentUser]);


  useEffect(() => {
    //render
  }, [tree, currentPath]);

  useEffect(() => {
    //render
    console.log("ProductMenu useEffect (activeMenu)", activeMenu);
    setCurrentPath(getPathIds(activeMenu));

    if (!!activeMenu && !!activeMenu.clasaWeb) {
      const clasaWeb = activeMenu.clasaWeb;
      document.title = activeMenu.name + " - Rolling";

      dispatch(setLoading(true));
      dispatch(clearError());

      navigate('/categorie/' + clasaWeb); // update bar TODO find some url
      ApiGet(`/search?cat=${clasaWeb}`, dispatchSearchResults, searchError);
    } else {
      if (!!activeMenu) {
        // clasa web not set
        dispatchSearchResults([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMenu]);

  const onClickMenu = (menuItem) => {
    console.log("onClickMenu", menuItem);

    dispatch(setActiveMenu(menuItem));


  }

  // Component for a leaf node (clickable link)
  const MenuLeaf = ({ menuItem }) => {
    let isActive = !!currentPath ? currentPath.includes(menuItem.id) : false;
    return (
      <span
        className={`dropdown-item text-decoration-none ${isActive ? 'active' : ''}`}
        onClick={() => onClickMenu(menuItem)}
        key={menuItem.id}
      >
        <strong>{menuItem.name}</strong> <br />
        <small>{menuItem.description}</small>
      </span>
    );
  };

  // Main menu with dropdowns
  const MenuDropDown = ({ menuItem }) => {
    let isActive = !!currentPath ? currentPath.includes(menuItem.id) : false;

    return (
      <li className={`nav-item dropdown ${isActive ? 'active' : ''}`} key={menuItem.id}>
        <span
          className={`nav-link dropdown-toggle ${isActive ? 'active' : ''}`}
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          style={{ cursor: "pointer" }}
          key={`${menuItem.id}-span`}
        >
          {menuItem.name}
        </span>
        <ul className="dropdown-menu" key={`${menuItem.id}-ul`}>
          {!!tree && !!tree.map && tree.filter(item => item.parentId === menuItem.id)
            .map((item) => (
              <MenuLeaf key={`${item.id}-leaf`} menuItem={item} />
            ))}
        </ul>
      </li>
    );
  };

  // Main Menu Component
  const Menu = () => (
    <nav id="product-menu" className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <ul className="navbar-nav flex-wrap">
          {!!tree && !!tree.map && tree
            .filter(item => item.parentId === 0)
            .map(item => (
              <MenuDropDown key={item.id} menuItem={item} />
            ))}
        </ul>
      </div>
    </nav>
  );

  return (
    <Menu data={tree} />
  );

}