import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setError, setTempConfirmation } from "store/msgStore";
import { ApiPut } from "services/ApiService";
import { MdExpandMore, MdChevronRight, MdExpand } from "react-icons/md";

import CheckboxTree from 'react-checkbox-tree';



export default function AdminUserTypesEditClaseAccess({ userType, refresh }) {
    const [nodesClase, setNodesClase] = useState([]);

    const simbolClase = useSelector((store) => store.products.simbolClase);

    const dispatch = useDispatch();

    const prepareTree = () => {
        if (!simbolClase) {
            return;
        }

        let tree = Object.keys(simbolClase)
            .map((key) => {
                let node = { value: simbolClase[key].id, label: simbolClase[key].display }
                //tree.push(node);
                return node;
            })
            .sort((a, b) => { return a.label.localeCompare(b.label) });

        setNodesClase(tree);
    }

    useEffect(() => {
        console.log("userType", userType);
        if (!!userType.claseAccess) {
            console.log("checked", userType.claseAccess.map(r => '' + r));
            setChecked(userType.claseAccess.map(r => '' + r));
        }
    }, [userType]);

    useEffect(() => {
        // render
    }, []);

    useEffect(() => {
        prepareTree();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simbolClase]);

    useEffect(() => {
        //render
    }, [userType, nodesClase]);


    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const onCheck = (checked) => {
        console.log("setChecked", checked)
        setChecked(checked);

        let payload = { claseAccess: checked };

        ApiPut('/user-type-clase/' + userType.id, payload,
            (dataOk) => {
                console.log("Save OK", dataOk);
                dispatch(setTempConfirmation("Accesul la clase a fost modificat cu success."))
                prepareTree();
                refresh();
            },
            (error) => {
                dispatch(setError(error));
            });
    }

    /* future for drag/drop: https://rct.lukasbach.com/docs/guides/uncontrolled-environment */
    /* https://github.com/jakezatecky/react-checkbox-tree */
    return (
        <>
            <Alert variant="light text-muted small py-1">Discount aplicat claselor unde este permis accesul. Discountul se va aplica peste celelalte discounturi.</Alert>
            <CheckboxTree
                nodes={nodesClase}
                checked={checked}
                expanded={expanded}
                onCheck={onCheck}
                onExpand={(expanded) => setExpanded(expanded)}
                nativeCheckboxes={true}

                icons={{
                    // check: <span className="rct-icon rct-icon-check" />,
                    //  uncheck: <span className="rct-icon rct-icon-uncheck" />,
                    //  halfCheck: <span className="rct-icon rct-icon-half-check" />,
                    expandClose: (<MdChevronRight style={{ width: '20px', height: '20px', marginTop: '8px' }} />),
                    expandOpen: (<MdExpandMore style={{ width: '20px', height: '20px', marginTop: '8px' }} />),
                    expandAll: (<MdExpand style={{ width: '20px', height: '20px', marginTop: '8px' }} />),
                    collapseAll: (<MdExpand style={{ width: '20px', height: '20px', marginTop: '8px' }} />),
                    parentClose: (<></>),
                    parentOpen: (<></>),
                    leaf: (<></>),
                }}
            />


        </>

    );
}